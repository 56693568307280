import React, { useEffect } from 'react'
import '../css/Listing_your_exchange.css';
import '../css/Listing_exchange_responsive.css';
import Stealthex_Offer from '../images/Stealthex Offer.png';
import Exolix_logo from '../images/exolix.png';
import letsexchange from '../images/letsexchange.png';
import Godex from '../images/godex.png';
import changehero from '../images/changehero.png';
import simpleswap from '../images/simpleswap.png';
import changelly from '../images/changelly.png';
import Change_Now_Logo from '../images/changenow.png';
import LetsExchange_Logo from '../images/letsexchange.png';
import yellow_star from '../images/yellow star.png';
import Email_Icon from '../images/Email Icon.png';
function Listing_Your_Exchange() {

    useEffect(()=>{
        window.scrollTo(0, 0);
    });
    
  return (
    <>
    {/*echange_section start*/}
    <section className='echange_section'>
        <div className="container text-center">
            <h1 className='list_heading exchange_heading'>List Your <strong>Exchange</strong></h1>
            <p className='exchange_para list_para'>Join Our Exchange Partner Network By Listing Your Exchange With Coinoswap. Enhance Your Revenue Stream Through Collaboration With Us And Share Profits Generated From Exchange Fees. We Offer An Equal 50/50 Split Between Your Exchange And Coinoswap</p>
        </div>
    </section>
    {/*echange_section end*/}

    {/*echange_logo_section start*/}
    <section className='exchange_logo'>
        <div className="container">
            <div class="logo-section">
                <div class="row logo_row">
                    <div class="logo-container"><img src={Stealthex_Offer} alt="StealthEX"/></div>
                    <div class="logo-container"><img src={Exolix_logo} alt="Exolix"/></div>
                    <div class="logo-container"><img src={letsexchange} alt="LetsExchange"/></div>
                </div>
                    
                <div class="row logo_row">
                    <div class="logo-container"><img src={Godex} alt="Godex"/></div>
                    <div class="logo-container"><img src={changehero} alt="ChangeHero"/></div>
                    <div class="logo-container"><img src={simpleswap} alt="SimpleSwap"/></div>
                </div>
                    
                <div class="row logo_row">
                    <div class="logo-container"><img src={changelly} alt="Changelly"/></div>
                    <div class="logo-container"><img src={Change_Now_Logo} alt="ChangeNow"/></div>
                    <div class="logo-container"><img src={LetsExchange_Logo} alt="LetsExchange"/></div>
                </div>
            </div>
        </div>
    </section>
    {/*echange_logo_section end*/}

    {/*Reasons_To_List section start*/}
    <section className='reasons_section'>
        <div className="container">
            <div class="reasons-to-list">
            <h2 className='exchange_heading'>Reasons To List?</h2>
            <ul>
            <li><img src={yellow_star} alt="Icon" /> Increased Traffic, Resulting In Higher Monetization.</li>
            <li><img src={yellow_star} alt="Icon" /> Feature Alongside Established And Reliable Swap Exchanges.</li>
            <li><img src={yellow_star} alt="Icon" /> Receive Additional User Reviews For Your Swap Exchange.</li>
            <li><img src={yellow_star} alt="Icon" /> Enhance Your Overall Business Reputation.</li>
            </ul> 
            </div>
        </div>
    </section>
    {/*Reasons_To_List section start*/}

    {/*Mail section start*/}
    <section className='mail'>
        <div className="container text-center">
            <h1 className='Email_heading exchange_heading'>Email:</h1>
            <img className='mail_image' src={Email_Icon} alt="" />
            <h4 className='partnership_mail exchange_heading'><a href="mailto:Partnerships@Coinoswap.Com" target='_blank'>Partnerships@Coinoswap.Com</a></h4>
        </div>
    </section>
    {/*Mail section end*/}
    </>
  )
}

export default Listing_Your_Exchange