import React, {useState, useEffect, useRef} from 'react';
import '../css/SubmitDeposit_responsive.css';
import ProgressInputWalletAddress from '../Components/progressInputWalletAddress.js';
import four from '../images/4.png';
import five from '../images/5.png';
import yellowline from '../images/yellow line.png';
import verticalline from '../images/vertical line.png';
import floatingicon from '../images/Floating Icon.png';
import fixedicon from '../images/Fixed Icon.png';
import {toast, Bounce } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import copyicon from "../images/Copy Icon_white.png";
import Draggable from "react-draggable";
import back_button from "../images/Back Button.png";

const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

const Submit=()=>{

    const navigate = useNavigate();

    const initialSessionValue = parseInt(sessionStorage.getItem('timerValue'));
    const [isRunning, setIsRunning] = useState(initialSessionValue === 1);
    const [timerseconds, setTimerSeconds]=useState("00:00:00");
    const [futuretime, setFutureTime]=useState();
    const [isfuturetimeused, setIsFutureTimeUsed]=useState(false);
    const [headingwhite, setHeadingWhite]=useState(sessionStorage.getItem('depositstatus')==1?"Scan To":"Deposit Time");
    const [headingyellow, setHeadingYellow]=useState(sessionStorage.getItem('depositstatus')==1?"Send":"Expired!");
    const [dragPositionRecipientWalletAddress, setDragPositionRecipientWalletAddress] = useState({ x: 0, y: 0 });
    const [dragDepositAddress, setDragDepositAddress] = useState({ x: 0, y: 0 });

    const recipientWalletRef = useRef(null);
    const depositWalletRef = useRef(null);

    const handleDrag = (e, ui, setPosition, ref) => {
        const parentWidth = ref.current.parentElement.offsetWidth;
        const textWidth = ref.current.offsetWidth;
        const maxDragX = parentWidth - textWidth;

        setPosition((prevPosition) => {
            const newX = prevPosition.x + ui.deltaX;
            return {
                x: Math.min(0, Math.max(newX, maxDragX)),  // Clamping the x position
                y: 0, // We are only moving in the x direction
            };
        });
    };

    const handleDragRecipientWallet = (e, ui) => handleDrag(e, ui, setDragPositionRecipientWalletAddress, recipientWalletRef);
    const handleDragDepositWallet = (e, ui) => handleDrag(e, ui, setDragDepositAddress, depositWalletRef);

    let apiInterval;
    let handeonetimeerrorstate;

    const handleOneTimeErrorState=(state)=>{
        handeonetimeerrorstate=state;
    }

      useEffect(()=>{
        let timerInterval1;
        if(isRunning && futuretime){
            timerInterval1= setInterval(()=>{
                const currentDate = new Date();
                const timestamp = currentDate.getTime();
                const storedTimestamp = Number(futuretime);
                if(storedTimestamp>=timestamp){
                    const seconds = Math.floor((storedTimestamp - timestamp) / 1000);
                    setTimerSeconds(formatTime(seconds));
                }else{
                    clearInterval(timerInterval1);
                    setHeadingWhite("Deposit Time");
                    setHeadingYellow("Expired!");
                    sessionStorage.setItem('timerValue', 0);
                    setIsRunning(false);
                }
    
            },1000)
        }
        return () => clearInterval(timerInterval1);
      },[futuretime]);

      const apiCall=async(url, name)=>{

            apiInterval=setInterval(async()=>{
                try { 
                    const options={
                        method:"POST",
                        headers:{
                          "Content-Type":"application/json"
                        },
                        body:JSON.stringify({
                            id:sessionStorage.getItem("ordertrackerid")
                        })
                      }
                    const response=await fetch(url, options);
                    const data=await response.json(response);
                    console.log("After api call");
                    if(!isfuturetimeused){
                        setFutureTime(data.tx.expiry_time);
                        setIsFutureTimeUsed(true);
                    }
                    if(data.tx.status=="waiting" || data.tx.status=="new" || data.tx.status=="wait"){

                    }else if(data.tx.status=="exchanging" || data.tx.status=="sending" || data.tx.status=="sending_confirmation" || data.tx.status=="hold" || data.tx.status=="hold*" || data.tx.status=="verifying"){
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_link);
                        navigate("/progress");
    
                    }else if(data.tx.status=="confirming" || data.tx.status=="confirmed" || data.tx.status=="confirmation"){
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_link);
                        navigate("/progress");

                    }else if(data.tx.status=="finished" || data.tx.status=="success"){
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_link);
                        navigate("/success");
    
                    }else if(data.tx.status=="failed" || data.tx.status=="error" || data.tx.status=="blacklist" || data.tx.status=="aml_check_failed" ){
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_link);
                        navigate("/progress");
    
                    }else if(data.tx.status=="refunded"){
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_link);
                        navigate("/progress");
    
                    }else if(data.tx.status=="expired" || data.tx.status=="overdue"){
                        toast.error('Deposit time expired!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Bounce,
                        });
                    }else{
                        console.log("Else part",data);
                        toast.error('Deposit time expired!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Bounce,
                        });
                    }
                    handleOneTimeErrorState(false);
                } catch (error) {
                    if(!handeonetimeerrorstate){
                        toast.error('Network error! Please refresh.', {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Bounce,
                        });
                        handleOneTimeErrorState(true);
                    }
                }   
                
            },2000)
    }

    useEffect(()=>{
        
        window.scrollTo(0, 0);

            if(sessionStorage.getItem("local_exchange_name")=="changelly"){
                apiCall(process.env.REACT_APP_URL+"/tx/changelly/status", "changelly");
            }else if(sessionStorage.getItem("local_exchange_name")=="changenow"){
                apiCall(process.env.REACT_APP_URL+"/tx/changenow/status", "changenow");
            }else if(sessionStorage.getItem("local_exchange_name")=="simpleswap"){
                apiCall(process.env.REACT_APP_URL+"/tx/simpleswap/status", "simpleswap");
            }else if(sessionStorage.getItem("local_exchange_name")=="changehero"){
                apiCall(process.env.REACT_APP_URL+"/tx/changehero/status", "changehero");
            }else if(sessionStorage.getItem("local_exchange_name")=="exolix"){
                apiCall(process.env.REACT_APP_URL+"/tx/exolix/status", "exolix");
            }else if(sessionStorage.getItem("local_exchange_name")=="letsexchange"){
                apiCall(process.env.REACT_APP_URL+"/tx/letsexchange/status", "letsexchange");
            }else if(sessionStorage.getItem("local_exchange_name")=="stealthex"){
                apiCall(process.env.REACT_APP_URL+"/tx/stealthex/status", "stealthex");
            }else if(sessionStorage.getItem("local_exchange_name")=="godex"){
                apiCall(process.env.REACT_APP_URL+"/tx/godex/status", "godex");
            }
        return () => clearInterval(apiInterval); // Cleanup on component unmount or when isRunning changes
    },[]);

    function copyOrderTacker(){
        if(isRunning){
            navigator.clipboard.writeText(sessionStorage.getItem("ordertrackerid"))
            .then(() => {
            toast.success('Order Tracker ID copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          })
          .catch((err) => {
            toast.error('Failed copying Order Tracker ID!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          });
        }
    }

        function copyDepositAddress(){
            if(isRunning){
                navigator.clipboard.writeText(sessionStorage.getItem("depositaddress"))
                .then(() => {
                toast.success('Deposit Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Deposit Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
            }
        }

        function copyRecipientAddress(){
            if(isRunning){
                navigator.clipboard.writeText(sessionStorage.getItem("recipientaddress"))
                .then(() => {
                toast.success('Recipient Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Recipient Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
            }
        }

    return(<>
    <ProgressInputWalletAddress progress={"submit"}></ProgressInputWalletAddress>
    <div className='enter-address-text-div'>
    <span><a href="#"> <img className='back_btn' src={back_button} alt="" /></a>{headingwhite} </span><span>{headingyellow}</span><span>Scan The QR Code Below Or Copy The Address And Send <span style={{color:"white"}}>{localStorage.getItem("local_send_amount")}</span> <img className='img-fluid send-coin-logo-submit-deposit' src={sessionStorage.getItem("local_send_crypto_logo")}></img> {sessionStorage.getItem("local_send_crypto_name")==sessionStorage.getItem("local_send_crypto_network")?sessionStorage.getItem("local_send_crypto_name"):sessionStorage.getItem("local_send_crypto_name")+" "+sessionStorage.getItem("local_send_crypto_network")}</span>
    </div>

    <div className='row QRadress_desktop'>
        <div className='col-5'>
            <div>
                <div style={{display:"flex"}}>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Order Tracker ID:</span>
                    <div className='rounded-div'><span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("ordertrackerid")}<img src={copyicon} id={isRunning?'thumb-cursor':''} onClick={copyOrderTacker} className='copy-icon'></img></span></div>
                </div>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Remaining Time:</span>
                    <div className='rounded-div basement-font' style={{color:"#EF960F"}}>{timerseconds}</div>
                </div>
                </div>
            </div>
            <div>
            <div style={{display:"flex", marginTop:"30px"}}>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Type:</span>
                    <div className='rounded-div'><div className='trx-type-submit'><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div></div>
                </div>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Partner:</span> 
                    <div className='submit-partner-logo' style={{marginTop:"15px"}}><img className='partner-image' src={sessionStorage.getItem("local_exchange_logo")}></img></div>
                </div>
                </div>
            </div>
        </div>
        <div className='col-2' style={{display:"flex", justifyContent:"center"}} ><div className='QR_code' style={{ background: 'white', padding: '20px', borderRadius:"20px", width:"240px", paddingBottom: "15px", height:"fit-content" }}><QRCode className='QR_inner' value={sessionStorage.getItem("depositaddress")} style={{ height: "auto", width: "200px", filter:isRunning?"blur(0px)": "blur(5px)"}}></QRCode></div></div>
        <div className='col-5 submit_uls' style={{paddingRight:"60px"}}>
            <ul className='submit-ul'>
                <li className='submit-li'>Kindly Send The Funds To The Address Below And Ensure That The Deposit Amount Matches What You Are Requesting.</li>
                <li className='submit-li'>The Status Of Your Swap Will Be updated As Soon As The Exchange Partner Receives Your Required Deposit.</li>
                <li className='submit-li'>Please Note That Swap Times Vary Based On The Specific Number Of Blockchain Confirmations Required And The Speed Of The Blockchain Network At The Time Of Your Swap.</li>
            </ul>
            <div style={{marginLeft:"50px"}}><span style={{color: "#928F8C", fontSize:"13px"}}>* It Is Recommended To Retain Your Order Tracker ID Until The Completion Of The Swap. Should You Have Any Queries, Please Do Not Hesitate To Reach Out To Your Support Team.</span></div>
            <a style={{color:"#EF960F", fontSize:"13px", marginLeft:"50px"}}>support@coinoswap.com</a>
        </div>
    </div>
    <div className='row QRadress_mobile'>
        <div className='col-12 QR_col' style={{display:"flex", justifyContent:"center"}} ><div className='QR_code' style={{ background: 'white', padding: '20px', borderRadius:"20px", width:"240px", paddingBottom: "15px", height:"fit-content" }}><QRCode className='QR_inner' value={sessionStorage.getItem("depositaddress")} style={{ height: "auto", width: "200px", filter:isRunning?"blur(0px)": "blur(5px)"}}></QRCode></div></div>
        <div style={{width:"100%"}}>
        <div className=' col-12 deposit_adres'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Deposit Address:</span>
                    <div className='rounded-div rounded-div-align-center'>
                        {/* <span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("depositaddress").substring(0, 20)} . . .<img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyDepositAddress}  className='copy-icon'></img></span> */}
                        <div className='submit-deposit-address-width'><Draggable axis="x" onDrag={handleDragDepositWallet}  position={{ x: dragDepositAddress.x, y: 0 }} ><span ref={depositWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragDepositAddress.x}px)`}}>{sessionStorage.getItem("depositaddress")}
                    </span></Draggable></div><img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyDepositAddress}  className='copy-icon'></img>
                        </div>
        </div>
        <div className=' col-12 recipient-adress'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Recipient Address:</span>
                <div className='rounded-div rounded-div-align-center'>
                        {/* <span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("recipientaddress").substring(0, 25)} . . .<img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyRecipientAddress} className='copy-icon'></img></span> */}
                        <div className='recipient-address-submit-width'><Draggable axis="x" onDrag={handleDragRecipientWallet}  position={{ x: dragPositionRecipientWalletAddress.x, y: 0 }} ><span ref={recipientWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPositionRecipientWalletAddress.x}px)`}}>{sessionStorage.getItem("recipientaddress")}
                    </span></Draggable></div>
                    <img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyRecipientAddress} className='copy-icon'></img>
                </div>
                    
        </div>
        </div>
        <div>
            <div>
                <div className='time_schedule'>
                <div className='col-12 Remaining_time'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Remaining Time:</span>
                    <div className='rounded-div basement-font' style={{color:"#EF960F"}}>{timerseconds}</div>
                </div>
                <div className=' col-12 exchange_partner'>
                    <span className='basement-font submit-data exchange_heading' style={{color:"white"}}>Exchange Partner:</span>
                    <div className='submit-partner-logo' style={{marginTop:"15px"}}><img className='img-fluid exchange_img' src={sessionStorage.getItem("local_exchange_logo")}></img></div>
                </div>
                </div>
            </div>
            <div>
            <div className='trackID'>
                <div className=' col-12 order_track'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Order Tracker ID:</span>
                    <div className='rounded-div'><span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("ordertrackerid")}<img src={copyicon} id={isRunning?'thumb-cursor':''} onClick={copyOrderTacker} className='copy-icon'></img></span></div>
                </div>
                <div className='col-12 exchange_type'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Type:</span>
                    <div className='rounded-div'><div className='trx-type-submit'><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div></div>
                </div>
                </div>
            </div>
        </div>
        <div className='col-12 submit_uls'>
            <ul className='submit-ul'>
                <li className='submit-li'>Kindly Send The Funds To The Address Below And Ensure That The Deposit Amount Matches What You Are Requesting.</li>
                <li className='submit-li'>The Status Of Your Swap Will Be updated As Soon As The Exchange Partner Receives Your Required Deposit.</li>
                <li className='submit-li'>Please Note That Swap Times Vary Based On The Specific Number Of Blockchain Confirmations Required And The Speed Of The Blockchain Network At The Time Of Your Swap.</li>
            </ul>
            <div style={{marginLeft:"50px"}}><span style={{color: "#928F8C", fontSize:"13px"}}>* It Is Recommended To Retain Your Order Tracker ID Until The Completion Of The Swap. Should You Have Any Queries, Please Do Not Hesitate To Reach Out To Your Support Team.</span></div>
            <a style={{color:"#EF960F", fontSize:"13px", marginLeft:"50px"}}>suppot@coinoswap.com</a>
        </div>
    </div>
    <div style={{width:"100%"}}>
        <div style={{margin:"30px auto", textAlign:"center", width:"25%"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Deposit Address:</span>
                    <div className='rounded-div rounded-div-align-center'>
                        {/* <span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("depositaddress").substring(0, 20)} . . .<img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyDepositAddress}  className='copy-icon'></img></span> */}
                        <div className='submit-deposit-address-width'><Draggable axis="x" onDrag={handleDragDepositWallet}  position={{ x: dragDepositAddress.x, y: 0 }} ><span ref={depositWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragDepositAddress.x}px)`}}>{sessionStorage.getItem("depositaddress")}
                    </span></Draggable></div><img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyDepositAddress}  className='copy-icon'></img>
                        </div>
        </div>
        <div style={{margin:"20px auto", textAlign:"center", width:"30%"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Recipient Address:</span>
                <div className='rounded-div rounded-div-align-center'>
                        {/* <span style={{filter:isRunning?"blur(0px)": "blur(3px)"}}>{sessionStorage.getItem("recipientaddress").substring(0, 25)} . . .<img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyRecipientAddress} className='copy-icon'></img></span> */}
                        <div className='recipient-address-submit-width'><Draggable axis="x" onDrag={handleDragRecipientWallet}  position={{ x: dragPositionRecipientWalletAddress.x, y: 0 }} ><span ref={recipientWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPositionRecipientWalletAddress.x}px)`}}>{sessionStorage.getItem("recipientaddress")}
                    </span></Draggable></div>
                    <img src={copyicon} id={isRunning?'thumb-cursor':''}  onClick={copyRecipientAddress} className='copy-icon'></img>
                </div>
                    
            </div>
    </div>

    <div className='recipient-input-how-to'><span>How To Swap Crypto</span><span>Coinoswap Is A Non-Custodial Crypto Exchange Aggregator Providing </span><span>Convenient And Speedy Exchanges To Swap Pairs.</span></div>
    <div className='three-and-four-step'>
        <div><img src={four}></img><div><span>Step 4</span><span>Send The Required Deposit</span><span>To The Designated</span><span>Address</span></div></div>
        <div><img className='img-fluid horizontal_line' src={yellowline}></img><img className='img-fluid vertical_line' src={verticalline} style={{display:"none"}}></img></div>
        <div><img src={five}></img><div><span>Step 5</span><span>Wait Until Your Swap</span><span>Is Successfully</span><span>Completed.</span></div></div>
    </div>
    </>);
}

export default Submit;