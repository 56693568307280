import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/navbar.js';
import BackgroundImage from './Components/background.js';
import Footer from './Components/footer.js';
import Home from './Pages/Home.js';
import Offers from './Pages/Offers.js';
import Deposit from './Pages/Deposit.js';
import Submit from './Pages/SubmitDeposit.js';
import { setItem } from './Js/localstorage.js';
import Progress from './Pages/Progress.js';
import Success from './Pages/Success.js';
import HowItWorks from './Pages/How-it-works.js';
import AboutUS from './Pages/About_us.js';
import FAQ from './Pages/FAQ.js';
import OurCurrencies from './Pages/Our_Currencies.js';
import Contact_us from './Pages/Contact_us'; 
import Listing_Your_Exchange from './Pages/Listing_Your_Exchange.js';
import Privacy_Policy from './Pages/Privacy_Policy.js';
import Terms_of_use from './Pages/Terms_of_use.js';
import AvailableSoon from './Pages/AvailableSoon.js';
import Affiliate_Program from './Pages/Affiliate_Program.js'


function App() {
  setItem();
  return (

    <Router scrollBehavior="auto top">
      <BackgroundImage>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/how-it-works" element={<HowItWorks />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/our_currencies" element={<OurCurrencies />} />
          <Route exact path="/about_us" element={<AboutUS/>} />
          <Route exact path="/contact_us" element={<Contact_us/>} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/deposit" element={<Deposit />} />
          <Route exact path="/submit" element={<Submit />} />
          <Route exact path="/progress" element={<Progress />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/listing_your_exchange" element={<Listing_Your_Exchange />} />
          <Route exact path="/terms_of_use" element={<Terms_of_use />} />
          <Route exact path="/privacy_policy" element={<Privacy_Policy />} />
          <Route exact path="/available_soon" element={<AvailableSoon />} />
          <Route exact path="/affiliate_program" element={<Affiliate_Program />}></Route>
        </Routes>
        <Footer />
      </BackgroundImage>
    </Router> 
  );
} 

export default App;
