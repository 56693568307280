import React, {useEffect} from 'react';
import '../css/Our_Currencies.css';
import '../css/Our_Currencies_responsive.css';
import search_icon from '../images/Search Icon.png'; 
import BitcoinIcon from '../images/Bitcoin Icon.png';
import EthIcon from '../images/Etherium Coin Icon.png';
import UsdtIcon from '../images/Tether Coin Icon.png';

const OurCurrencies = () => {
  const data = [
    { coinName: 'BTC', coinFullName: 'Bitcoin', price: '$62108.449', change: '-1.886%', volume: '$6261163549.256', icon: BitcoinIcon },
    { coinName: 'ETH', coinFullName: 'Etherium', price: '$2986.757', change: '-0.677%', volume: '$3955543548.929', icon: EthIcon },
    { coinName: 'USDT', coinFullName: 'Tether', price: '$1.000', change: '0.017%', volume: '$14352666626.957', icon: UsdtIcon },
  ];

  useEffect(()=>{
    window.scrollTo(0, 0);
  });

  return (
    <>
     {/*currencies section start*/}
     <div className="container-fluid">
       <div className="currencies-section">
        <h1 className='currencies_heading'>Our <strong className='currencies_strong'>Currencies</strong></h1>
        <p className='currencies_para'>Check the latest cryptocurrency prices, 24h changes, and exchange your coins here.</p>
      </div>
    </div>
   {/*currencies section end*/}

    {/*Currencies Table Section Start*/}
    <section className='table_section'>
      <div className="container-fluid mobile_container">
       
       {/* Search box */}
       <div className="search-container">
          <label htmlFor="search" className="search-label">Search Currencies</label>
          <div className="search-box-wrapper">
            <input
              type="text"
              id="search"
              className="search-box"
              placeholder="Type To Search For Cryptocurrency  .  .  ."
            />
            <span className="search-icon">
              <img src={search_icon} alt="search" />
            </span>
          </div>
        </div>

        {/* Custom "div-based" table structure */}
        <div className="row table-header">
          <div className="col-3"><h4>Coin Name</h4></div>
          <div className="col-2"><h4>Price</h4></div> {/* Corrected class */}
          <div className="col-2"><h4>24H</h4></div>
          <div className="col-3"><h4>24H Volume</h4></div>
          <div className="col-2"><h4>Available</h4></div>
        </div>

        {data.map((row, index) => (
          <div key={index} className="row table-row align-items-center py-3">
            <div className="col-3 coin-info d-flex align-items-center">
              <img src={row.icon} alt={row.coinName} className="coin-icon me-2" />
              <div>
                <strong>{row.coinName}</strong>
                <p className="coin-fullname">{row.coinFullName}</p>
              </div>
            </div>
            <div className="col-2 price_info t_data" style={{ color: row.change.startsWith('-')}}>
              {row.price}
            </div>
            <div className="col-2 H_info t_data">
              {row.change}
            </div>
            <div className="col-3 t_data volum_data">
              {row.volume}
            </div>
            <div className="col-2">
              <button className="exchange-button d-flex align-items-center">
                <span className="exchange-icon"></span>
                Exchange
              </button>
            </div>
          </div>
        ))}

      </div>
    </section>
    {/*Currencies Table Section End*/}

    </>
  );
};

export default OurCurrencies;
